// Imports
import {
  make,
} from 'vuex-pathify'

const state = {
  products: {},
  config: {
    version: {
      current: null,
      block: null,
      status: 'updated',
    },
    table: {
      locks: {
        events: {
          offset: 48,
          unit: "hours"
        },
        cancellation: {
          offset: 48,
          unit: "hours"
        },
        toggleable: {
          offset: 47,
          unit: "hours"
        },
        people: {
          offset: 8,
          unit: "hours"
        },
        photos: {
          offset: 0,
          unit: "hours"
        }
      }
    }
  },
};

const mutations = make.mutations(state)

const actions = {}

const getters = {}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}
