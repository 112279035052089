import { createRouter, createWebHistory } from 'vue-router'
import kebabCase from 'lodash/kebabCase'
import { storage } from '@/services/global'


function layout (path, name, children) {
  const folder = kebabCase(name);

  return {
    path,
    component: () => import(`@/layouts/${folder}/Index.vue`),
    children,
  }
}

function redirect (redirect) {
  return { path: '/:pathMatch(.*)*', redirect }
}

function route (path, folder, name, file='Index', children=null, props=false) {
  return {
    path,
    name: !!children ? null : name,
    component: () => import(`@/views/${folder}/${file}.vue`),
    children,
    props
  }
}

function subroute (path, folder, name, file='Index', meta) {
  return {
    path,
    name,
    component: () => import(`@/views/${folder}/${file}.vue`),
    meta
  }
}

const routes = [
  layout('/', 'Main', [
    route('', 'home', 'home', 'Index'),
    route('settings/:setting/:step?', 'home', 'settings', 'Index'),
    route('signup/:section?/:subsection?', 'profile', 'signup', 'Index', null, true),
    route('profile/:section?/:subsection?', 'profile', 'profile', 'Index', null, true),
  ]),
  layout('/hello', 'Start', [
    route(':view?/:subview?', 'hello', 'hello', 'Index', null, true),
    route('terms', 'hello', 'terms', 'Terms'),
  ]),
  redirect('/hello'),
];



const router = createRouter({
  mode: 'history',
  base: import.meta.env.BASE_URL,
  scrollBehavior: (to, from, savedPosition) => {
    let scrollTo = 0

    if (to.hash) {
      scrollTo = to.hash
    } else if (savedPosition) {
      scrollTo = savedPosition.y
    }

    // return useGoTo(scrollTo)
  },
  history: createWebHistory(import.meta.env.BASE_URL),
  routes
});

router.beforeEach(async (to, from) => {
  // console.log('routing...', to, from);
  if (to.name!='signup'&&to.name!='hello'&&to.name!='login') {
    const user = await storage.get('user');
    const auth = await storage.get('auth');
    if (!user||!auth) {
      storage.set('redirect', to.path, 'session');
      return { path: '/hello', query: to.query };
    }
  }
});

export default router
